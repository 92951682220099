// import logo from './logo.svg';
import './App.css';
import Main from "./routes";
import React from "react";


function App() {
  return (
      <div className="App">
          <Main />
      </div>
  );
}

export default App;
